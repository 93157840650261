import Vue from 'vue'
import App from './App.vue'
import router from './router/router.js'; // 导入路由文件
import '@/icon'
import ElementUI from 'element-ui';             //全局引入element
import 'element-ui/lib/theme-chalk/index.css';    //全局引入element的样式
import {Message} from 'element-ui';

import axios from 'axios';

import store from './store/index';

import * as echarts from 'echarts';
import 'echarts-gl';


import VueClipboard from 'vue-clipboard2'
import VueCron from 'vue-cron';

import NProgress from 'nprogress';

import 'nprogress/nprogress.css';

import ElementResizeDetectorMaker from "element-resize-detector"

Vue.prototype.$erd = ElementResizeDetectorMaker()
Vue.use(VueCron);

Vue.use(ElementUI)

// 将 jwt_decode 添加到 Vue 的原型中，以便在组件中全局访问

Vue.use(VueClipboard)

Vue.prototype.$echarts = echarts

Vue.config.productionTip = false
Vue.use(ElementUI);    //全局注入element

// 本地
// axios.defaults.baseURL = 'http://localhost:8080/api'

// axios.defaults.baseURL = 'http://localhost:8009/api'

// 本地-pro
// axios.defaults.baseURL = 'http://192.168.31.143:8009/api'
//
// axios.defaults.baseURL = 'http://192.168.31.143:5001/api'

// ip
// axios.defaults.baseURL = 'http://192.168.31.143:8080/api';

// 服务器
axios.defaults.baseURL = 'http://www.nhniudan.com/api';


axios.defaults.withCredentials = true;
// 设置请求拦截器，在发送请求前加入token到请求头
axios.interceptors.request.use(
    config => {
        const token = store.getters.getToken;
        const expirationTime = store.getters.getExpirationTime;
        // 如果存在 token 并且过期时间大于当前时间，则刷新 token
        if (token && Date.now() > expirationTime) {
            Message({
                type: "warning",
                message: "登陆过期，请重新登陆!",
            });
            // 移除token
            store.commit('clearTokenOut');

            setInterval(() => {
                router.go(0);
                router.push('/login');
            }, 1000);
        }

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// 响应拦截器
axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        console.log('error', error)

        if (error.response === undefined) {
            Message({
                type: 'error',
                message: '系统报错了。。。'
            });
        } else {
            console.log(error)
        }

        let response = error.response;
        if (response.status === 403) {
            router.push('/login');
            Message({
                type: 'error',
                message: '没有权限访问，请先登录!'
            });
            // 移除token
            store.commit('clearTokenOut');
            router.push('/login');
        }
        console.log('拦截器', error)
        // 对响应错误做些什么
        // 可以在这里捕获和处理请求错误，并将错误信息传递给前端
        return Promise.reject(error);
    }
)

Vue.config.productionTip = false;

Vue.prototype.$previousRoute = null;

// vuex路由设置
router.beforeEach((to, from, next) => {

    Vue.prototype.$previousRoute = from;

    NProgress.start();

    let token = store.getters.getToken;
    if (to.path.startsWith('/login')) {
        next()
    } else if (token == null) {
        Message({
            type: 'error',
            message: '请先登录!'
        });
        next({
            path: '/login'
        })
    } else if (to.fullPath.startsWith('/index')) {
        next()
    } else {
        next()
    }
})


router.afterEach(() => {
    NProgress.done();
});


Vue.prototype.$axios = axios
new Vue({
    router,
    render: h => h(App),
    store,
}).$mount('#app')

