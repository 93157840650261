import Vue from 'vue';
import Router from 'vue-router';

import NotFound from '../views/404/index.vue'

Vue.use(Router);

export default new Router({
    mode: 'history',  // 使用 HTML5 history 模式
    routes: [
        {path: '*', component: NotFound},
        {
            path: '/login',
            component: () => import('@/views/system/login/login'),
        },
        {
            path: '/',
            redirect: 'login',
        },
        {
            path: '/niuhui',
            name: 'niuhui',
            component: () => import('@/components/index'),
            children: [
                {
                    path: '',
                    components: {
                        table: () => import('@/views/system/index/index.vue'),
                    },
                },
                {
                    path: 'user',
                    name: 'User',
                    components: {
                        table: () => import('@/views/system/user/User.vue'),
                    },
                },
                {
                    path: 'respSales',
                    name: 'respSales',
                    components: {
                        table: () => import('@/views/finance/respSales/inde'),
                    },
                },
                {
                    path: 'warehouse',
                    name: 'warehouse',
                    components: {table: () => import('@/views/operate/warehouse/index')},
                },
                {
                    path: 'unsalablePrice',
                    name: 'unsalablePrice',
                    components: {table: () => import('@/views/supply/unsalablePrice/index')},
                },
                {
                    path: 'abnormalLogistics',
                    name: 'abnormalLogistics',
                    components: {table: () => import('@/views/logistics/abnormalLogistics/index')},
                },
                {
                    path: 'requestOrder',
                    name: 'requestOrder',
                    components: {table: () => import('@/views/finance/requestOrder/index')},
                },
                {
                    path: 'requestProvider',
                    name: 'requestProvider',
                    components: {table: () => import('@/views/finance/requestProvider/index')},
                },
                {
                    path: 'dept',
                    name: 'dept',
                    components: {table: () => import('@/views/system/dept/index')},
                },
                {
                    path: 'newProduct',
                    name: 'newProduct',
                    components: {table: () => import('@/views/productM/product/newProduct')},
                },
                {
                    path: 'ctrCvr',
                    name: 'ctrCvr',
                    components: {table: () => import('@/views/productM/product/ctrCvr')},
                },
                {
                    path: 'targetResp',
                    name: 'targetResp',
                    components: {table: () => import('@/views/operate/targetResp/index')},
                },
                {
                    path: 'mkdNewProduct',
                    name: 'mkdNewProduct',
                    components: {table: () => import('@/views/operate/mkdNewProduct/index')},
                },
                {
                    path: 'ctrManager',
                    name: 'ctrManager',
                    components: {table: () => import('@/views/productM/ctr/index')},
                },
                {
                    path: 'expertInfo',
                    name: 'expertInfo',
                    components: {table: () => import('@/views/tiktok/expertInfo/index')},
                },
                {
                    path: 'analysis',
                    name: 'analysis',
                    components: {table: () => import('@/views/tiktok/analysis/index')},
                },
                {
                    path: 'status',
                    name: 'status',
                    components: {table: () => import('@/views/tiktok/status/index')},
                },
                {
                    path: 'expertIntentionalInfo',
                    name: 'expertIntentionalInfo',
                    components: {table: () => import('@/views/tiktok/expertIntentionalInfo/index')},
                },
                {
                    path: 'product',
                    name: 'product',
                    components: {table: () => import('@/views/tiktok/product/index')},
                },
                {
                    path: 'cooperate',
                    name: 'cooperate',
                    components: {table: () => import('@/views/tiktok/cooperate/index')},
                },
                {
                    path: 'video',
                    name: 'video',
                    components: {table: () => import('@/views/tiktok/video/index')},
                },
                {
                    path: 'sendSample',
                    name: 'sendSample',
                    components: {table: () => import('@/views/tiktok/sendSample/index')},
                },
                {
                    path: 'logisticsCost',
                    name: 'logisticsCost',
                    components: {table: () => import('@/views/logistics/logisticsCost/index')},
                },
                {
                    path: 'calculate',
                    name: 'calculate',
                    components: {table: () => import('@/views/logistics/logisticsCost/calculate')},
                },
                {
                    path: 'menu',
                    name: 'menu',
                    components: {table: () => import('@/views/system/menu/index')},
                },
                {
                    path: 'role',
                    name: 'role',
                    components: {table: () => import('@/views/system/role/index')},
                },
                {
                    path: 'auth',
                    name: 'auth',
                    components: {table: () => import('@/views/system/auth/index')},
                },
                {
                    path: 'logisticsTrack',
                    name: 'logisticsTrack',
                    components: {table: () => import('@/views/logistics/logisticsTrack/index')},
                },
                {
                    path: 'logisticsTrackTarget',
                    name: 'logisticsTrackTarget',
                    components: {table: () => import('@/views/logistics/logisticsTrackTarget/index')},
                },
                {
                    path: 'productEntrySearch',
                    name: 'productEntrySearch',
                    components: {table: () => import('@/views/supply/productEntrySearch/index')},
                },
                {
                    path: 'amzOldProductLevel',
                    name: 'amzOldProductLevel',
                    components: {table: () => import('@/views/amzOld/index')},
                },
                {
                    path: 'devLog',
                    name: 'devLog',
                    components: {table: () => import('@/views/system/devLog/index.vue')},
                },
                {
                    path: 'loginNotice',
                    name: 'loginNotice',
                    components: {table: () => import('@/views/system/loginNotice/index.vue')},
                },
                {
                    path: 'booth',
                    name: 'booth',
                    components: {table: () => import('@/views/stats/booth/index.vue')},
                },
                {

                    path: 'shein',
                    name: 'shein',
                    components: {table: () => import('@/views/multiplatform/shein/index.vue')},
                },
                {
                    path: 'createShipment',
                    name: 'createShipment',
                    components: {table: () => import('@/views/yingdao/createShipment/index.vue')},
                },
                {
                    path: 'mercadoBooking',
                    name: 'mercadoBooking',
                    components: {table: () => import('@/views/yingdao/mercadoBooking/index.vue')},
                },
                {
                    path: 'tiktok',
                    name: 'tiktok',
                    components: {table: () => import('@/views/multiplatform/tiktok/index.vue')},
                },
                {
                    path: 'sheinDataInfo',
                    name: 'sheinDataInfo',
                    components: {table: () => import('@/views/multiplatform/shein/data.vue')},
                },
                {
                    path: 'sheinData',
                    name: 'sheinData',
                    components: {table: () => import('@/views/multiplatform/shein/dataInfo.vue')},
                },
                {
                    path: 'tiktokDataInfo',
                    name: 'tiktokDataInfo',
                    components: {table: () => import('@/views/multiplatform/tiktok/dataInfo.vue')},
                },
                {
                    path: 'tiktokData',
                    name: 'tiktokData',
                    components: {table: () => import('@/views/multiplatform/tiktok/data.vue')},
                },
                {
                    path: 'log',
                    name: 'log',
                    components: {table: () => import('@/views/system/log/data.vue')},
                },
                {
                    path: 'color',
                    name: 'color',
                    components: {table: () => import('@/views/system/color/index.vue')},
                },
                {
                    path: 'platformSum',
                    name: 'platformSum',
                    components: {table: () => import('@/views/multiplatform/sum/index.vue')},
                },
                {
                    path: 'temu',
                    name: 'temu',
                    components: {table: () => import('@/views/multiplatform/temu/index.vue')},
                },
                {
                    path: 'shipmentsOrder',
                    name: 'shipmentsOrder',
                    components: {table: () => import('@/views/yingdao/shipmentsOrder/index.vue')},
                },
                {
                    path: 'temuData',
                    name: 'temuData',
                    components: {table: () => import('@/views/multiplatform/temu/data.vue')},
                },
                {
                    path: 'temuAccount',
                    name: 'temuAccount',
                    components: {table: () => import('@/views/multiplatform/temu/account.vue')},
                },
                {
                    path: 'temuShop',
                    name: 'temuShop',
                    components: {table: () => import('@/views/multiplatform/temu/shop.vue')},
                },
                {
                    path: 'temuDataInfo',
                    name: 'temuDataInfo',
                    components: {table: () => import('@/views/multiplatform/temu/dataInfo.vue')},
                },
                {
                    path: 'temuAlltrusteeship',
                    name: 'temuAlltrusteeship',
                    components: {table: () => import('@/views/multiplatform/temu/temuAlltrusteeship.vue')},
                },
                {
                    path: 'xxlJob',
                    name: 'xxlJob',
                    components: {table: () => import('@/views/system/xxlJob/index.vue')},
                },
                {
                    path: 'monitoringTask',
                    name: 'monitoringTask',
                    components: {table: () => import('@/views/system/monitoring/task.vue')},
                },
                {
                    path: 'platformOrder',
                    name: 'platformOrder',
                    components: {table: () => import('@/views/multiplatform/order/index.vue')},
                },
                {
                    path: 'productManagement',
                    name: 'productManagement',
                    components: {table: () => import('@/views/multiplatform/product/productManagement.vue')},
                },
                {
                    path: 'shipmentsRule',
                    name: 'shipmentsRule',
                    components: {table: () => import('@/views/yingdao/shipmentsOrder/shipmentsRule.vue')},
                },
                {
                    path: 'sendEcharts',
                    name: 'sendEcharts',
                    components: {table: () => import('@/views/multiplatform/order/echarts.vue')},
                },
                {
                    path: 'multiChannelFreight',
                    name: 'multiChannelFreight',
                    components: {table: () => import('@/views/multiChannelFreight/index.vue')},
                },
                {
                    path: 'logisticsDeadline',
                    name: 'logisticsDeadline',
                    components: {table: () => import('@/views/logistics/logisticsDeadline/index.vue')}
                },
                {
                    path: 'translationEvaluation',
                    name: 'translationEvaluation',
                    components: {table: () => import('@/views/supply/translationEvaluation/index.vue')}
                },
                {
                    path: 'provinceRegion',
                    name: 'provinceRegion',
                    components: {table: () => import('@/views/multiplatform/product/provinceRegion.vue')}
                },
                {
                    path: 'sendParam',
                    name: 'sendParam',
                    components: {table: () => import('@/views/multiplatform/product/sendParam.vue')}
                },
                {
                    path: 'logisticsDeadline',
                    name: 'logisticsDeadline',
                    components: {table: () => import('@/views/logistics/logisticsDeadline/index.vue')}
                },
                {
                    path: 'server',
                    name: 'server',
                    components: {table: () => import('@/views/system/monitoring/server.vue')}
                },
                {
                    path: 'swagger',
                    name: 'swagger',
                    components: {table: () => import('@/views/system/swagger/index.vue')}
                },
                {
                    path: "outsourcingOrders",
                    name: "outsourcingOrders",
                    components: {table: () => import('@/views/supply/outsourcingOrders/index.vue')}
                },
                {
                    path: "skuMapping",
                    name: "skuMapping",
                    components: {table: () => import('@/views/multiplatform/temu/skuMapping.vue')}
                },
                {
                    path: "repeatOrder",
                    name: "repeatOrder",
                    components: {table: () => import('@/views/supply/repeatOrder/index.vue')}
                },
                {
                    path: "repeatOrderInfo",
                    name: "repeatOrderInfo",
                    components: {table: () => import('@/views/supply/repeatOrder/repeatOrderInfo.vue')}
                },
                {
                    path: "repeatOrderInfoTarget",
                    name: "repeatOrderInfoTarget",
                    components: {table: () => import('@/views/supply/repeatOrder/target.vue')}
                },
                {
                    path: "adjustmentWarehouse",
                    name: "adjustmentWarehouse",
                    components: {table: () => import('@/views/supply/repeatOrder/adjustmentWarehouse.vue')}
                },
                {
                    path: "repeatOrderEcharts",
                    name: "repeatOrderEcharts",
                    components: {table: () => import('@/views/supply/repeatOrder/echarts.vue')}
                },
                {
                    path: "houseMissing",
                    name: "houseMissing",
                    components: {table: () => import('@/views/personnel/houseMissing/houseMissing.vue')}
                },
                {
                    path: "poOrder",
                    name: "poOrder",
                    components: {table: () => import('@/views/supply/poOrder/index.vue')}
                },
                {
                    path: "sheinStore",
                    name: "sheinStore",
                    components: {
                        table: () => import('@/views/multiplatform/shein/store.vue')
                    }
                },
                {
                    path: "clock",
                    name: "clock",
                    components: {table: () => import('@/views/personnel/clock/index.vue')}
                },
                {
                    path: "unitPrice",
                    name: "unitPrice",
                    components: {table: () => import('@/views/multiplatform/shein/unitPrice.vue')}
                },
                {
                    path: "deliveryDemand",
                    name: "deliveryDemand",
                    components: {table: () => import('@/views/supply/deliveryDemand/index.vue')}
                },
                {
                    path: "repeatOrderRules",
                    name: "repeatOrderRules",
                    components: {table: () => import('@/views/supply/repeatOrder/rules.vue')}
                },
                {
                    path: "midStageGoal",
                    name: "midStageGoal",
                    components: {table: () => import('@/views/supply/repeatOrder/test.vue')}
                },
                {
                    path: "outBound",
                    name: "outBound",
                    components: {table: () => import('@/views/personnel/outBound/index.vue')}
                },
                {
                    path: "mediaReport",
                    name: "mediaReport",
                    components: {table: () => import('@/views/media/mediaReport/index.vue')}
                },
                {
                    path: "totalDemandReport",
                    name: "totalDemandReport",
                    components: {table: () => import('@/views/media/mediaReport/totalDemandReport.vue')}
                },
                {
                    path: "mediaPerformance",
                    name: "mediaPerformance",
                    components: {table: () => import('@/views/media/mediaReport/mediaPerformance.vue')}
                },
                {
                    path: "translationCount",
                    name: "translationCount",
                    components: {table: () => import('@/views/supply/translationEvaluation/count.vue')}
                },
                {
                    path: "yiheBatch",
                    name: "yiheBatch",
                    components: {table: () => import('@/views/logistics/yiheBatch/index.vue')}
                },
                {
                    path: "poMonitor",
                    name: "poMonitor",
                    components: {table: () => import('@/views/supply/poOrder/poMonitor.vue')}
                },
                {
                    path: "profitStatement",
                    name: "profitStatement",
                    components: {table: () => import('@/views/finance/profitStatement/index.vue')}
                },
                {
                    path: "performance",
                    name: "performance",
                    components: {table: () => import('@/views/finance/profitStatement/performance.vue')}
                },
                {
                    path: "halfDay",
                    name: "halfDay",
                    components: {table: () => import('@/views/finance/profitStatement/temu/half/day.vue')}
                },
                {
                    path: "halfMonth",
                    name: "halfMonth",
                    components: {table: () => import('@/views/finance/profitStatement/temu/half/month.vue')}
                },
                {
                    path: "completeDay",
                    name: "completeDay",
                    components: {table: () => import('@/views/finance/profitStatement/temu/complete/day.vue')}
                },
                {
                    path: "completeMonth",
                    name: "completeMonth",
                    components: {table: () => import('@/views/finance/profitStatement/temu/complete/month.vue')}
                },
                {
                    path: "sheinCompleteDay",
                    name: "sheinCompleteDay",
                    components: {table: () => import('@/views/finance/profitStatement/shein/completeDay.vue')}
                },
                {
                    path: "sheinHaltDay",
                    name: "sheinHaltDay",
                    components: {table: () => import('@/views/finance/profitStatement/shein/haltDay.vue')}
                },
                {
                    path: "sheinThirdDay",
                    name: "sheinThirdDay",
                    components: {table: () => import('@/views/finance/profitStatement/shein/thirdPartyDay.vue')}
                },
                {
                    path: "tkCompleteDay",
                    name: "tkCompleteDay",
                    components: {table: () => import('@/views/finance/profitStatement/tiktok/completeDay.vue')}
                },
                {
                    path: "tkThirdDay",
                    name: "tkThirdDay",
                    components: {table: () => import('@/views/finance/profitStatement/tiktok/thirdPartyDay.vue')}
                },
                {
                    path: "tkThirdDay",
                    name: "tkThirdDay",
                    components: {table: () => import('@/views/finance/profitStatement/tiktok/thirdPartyDay.vue')}
                },
                {
                    path: "mercadoProfit",
                    name: "mercadoProfit",
                    components: {table: () => import('@/views/finance/profitStatement/mercado/profit.vue')}
                },
                {
                    path: "coupangDayProfit",
                    name: "coupangDayProfit",
                    components: {table: () => import('@/views/finance/profitStatement/coupang/index.vue')}
                },
                {
                    path: "shopeeDayProfit",
                    name: "shopeeDayProfit",
                    components: {table: () => import('@/views/finance/profitStatement/shopee/profit.vue')}
                },
                {
                    path: "sheinSkuError",
                    name: "sheinSkuError",
                    components: {table: () => import('@/views/finance/profitStatement/shein/skuError.vue')}
                },
                {
                    path: "mercadoRelationship",
                    name: "mercadoRelationship",
                    components: {table: () => import('@/views/finance/profitStatement/mercado/relationship.vue')}
                },
                {
                    path: "sheinSfs",
                    name: "sheinSfs",
                    components: {table: () => import('@/views/finance/profitStatement/shein/sfs.vue')}
                },
                {
                    path: "skuErrorThreeAndComplete",
                    name: "skuErrorThreeAndComplete",
                    components: {table: () => import('@/views/finance/profitStatement/shein/skuErrorThreeAndComplete.vue')}
                },
                {
                    path: "skuErrorHalfTemu",
                    name: "skuErrorHalfTemu",
                    components: {table: () => import('@/views/finance/profitStatement/temu/half/skuErrorHalf.vue')}
                },
                {
                    path: "tkAndShopeeRelationship",
                    name: "tkAndShopeeRelationship",
                    components: {table: () => import('@/views/finance/profitStatement/tiktok/relationship.vue')}
                },
                {
                    path: "skuCost",
                    name: "skuCost",
                    components: {table: () => import('@/views/finance/profitStatement/cost.vue')}
                },
                {
                    path: "yCInvoice",
                    name: "yCInvoice",
                    components: {table: () => import('@/views/logistics/yc/index.vue')}
                },
                {
                    path: "qcProduct",
                    name: "qcProduct",
                    components: {table: () => import('@/views/qcProduct.vue')}
                },
                {
                    path: "tkCompleteMonth",
                    name: "tkCompleteMonth",
                    components: {table: () => import('@/views/finance/profitStatement/tiktok/completeMonth.vue')}
                },
                {
                    path: "tkThreeMonth",
                    name: "tkThreeMonth",
                    components: {table: () => import('@/views/finance/profitStatement/tiktok/thirdPartyMonth.vue')}
                }
            ],
        },
        // // 都是不需要如component
        // {
        //     path: '/abnormalLogistics',
        //     name: 'abnormalLogistics',
        //     component: () => import('@/views/abnormalLogistics/index'),
        // },
        // {
        //     path: '/unsalablePrice',
        //     name: 'unsalablePrice',
        //     component: () => import('@/views/unsalablePrice/index'),
        // },
        // {
        //     path: '/respSales',
        //     name: 'respSales',
        //     component: () => import('@/views/respSales/inde'),
        // },
        // {
        //     path: '/warehouse',
        //     name: 'warehouse',
        //     component: () => import('@/views/warehouse/index'),
        // },
    ]
});

const createRouter = () =>
    new Router({
        mode: 'history', // require service support
        base: '/',
        scrollBehavior: () => ({y: 0}),
    });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher; // reset router
}

// 针对ElementUI导航栏中重复导航报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

